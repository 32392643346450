import React, { useRef, useEffect } from 'react';

const EditableText = ({ value, setValue, isActive, type, textAlign, color }) => {
    const editorRef = useRef(null);

    const applyFormatting = (command) => {
        document.execCommand(command, false, null);
        setTimeout(() => {
            if (editorRef.current) {
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    range.collapse(false);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        }, 50);
    };

    const getFontSize = (type) => {
        switch (type) {
            case 'p':
                return '16px';
            case 'h2':
                return '28px';
            case 'h3':
                return '20px';
            default:
                return '16px';
        }
    };

    const getFontWeight = (type) => {
        switch (type) {
            case 'p':
                return '500';
            case 'h2':
                return '700';
            case 'h3':
                return '600';
            default:
                return '500';
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.innerHTML = value;
        }
    }, [value]);

    return (
        <div className="flex flex-col w-full space-y-3">
            {isActive && (
                <div className="flex space-x-4 p-2 items-center justify-center bg-slate-200 rounded-3xl">
                    <button
                        className="flex items-center justify-center w-8 h-8 rounded-xl border-2 border-black bg-white"
                        onClick={() => applyFormatting('bold')}
                    >
                        B
                    </button>
                    <button
                        className="flex items-center justify-center w-8 h-8 rounded-xl border-2 border-black bg-white"
                        onClick={() => applyFormatting('italic')}
                    >
                        I
                    </button>
                    <button
                        className="flex items-center justify-center w-8 h-8 rounded-xl border-2 border-black bg-white"
                        onClick={() => applyFormatting('underline')}
                    >
                        U
                    </button>
                </div>
            )}
            <div
                ref={editorRef}
                contentEditable
                onInput={(e) => setValue(e.target.innerHTML)}
                className="cursor-text"
                style={{
                    textAlign: textAlign,
                    color: color,
                    fontSize: getFontSize(type),
                    fontWeight: getFontWeight(type),
                }}
            />
        </div>
    );
};

export default EditableText;
