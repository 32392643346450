import React from 'react'

export const h1 = ({ className, children }) => {
    return (
        <h1 className={`text-3xl font-sans font-semibold leading-[48px] ${className ? className : ''}`} dangerouslySetInnerHTML={{ __html: children }} />
    )
}

export const h2 = ({ className, children }) => {
    return <h2 className={`text-[24px] font-sans font-semibold leading-8 ${className ? className : ''}`} dangerouslySetInnerHTML={{ __html: children }} />
}

export const h3 = ({ className, children }) => {
    return <h3 className={`font-sans text-xl font-semibold leading-7 ${className ? className : ''}`} dangerouslySetInnerHTML={{ __html: children }} />
}

export const Normal = ({ className, children }) => {
    return <p className={`text-base font-sans leading-6 ${className ? className : ''}`} dangerouslySetInnerHTML={{ __html: children }} />
}

export const Small = ({ className, children }) => {
    return <p className={`text-[14px] leading-6 ${className ? className : ''}`}>{children}</p>
}

export const Link = ({ href, className, children }) => {
    return (
        <a href={href} className={`leading-5 underline underline-offset-2 ${className ? className : ''}`}>
            {children}
        </a>
    )
}
